import React from 'react';
import { Box, Flex } from '@chakra-ui/core';
import colors from '../../theme/colors';
import Anchor from './anchor';
import ContentContainer from '../ContentContainer';
import FAQs from '../jointAccount/FAQs';
import HelpfulResources from './HelpfulResources';
import HowItWorks from './howItWorks';
import TopSection from './TopSection';
import JointKeyFeatures from './jointKeyFeatures';
import CouplesLoveZeta from './CouplesLoveZeta';
import { JAFAQContent } from '../../lib/consts';

import VIP from './VIP';
import { APP_CONTENT_DESKTOP_WIDTH, APP_CONTENT_WIDE_WIDTH } from '../../styles/sizes';

import CardFeatures from '../jointAccount/cardFeatures';

const PageContent = () => {
  return (
    <Box mode="light" newNav noRootContainer>
      <Flex
        className="main-column"
        width="100%"
        justifyContent="center"
        direction="column"
      >
        <TopSection />

        <Flex mb={['50px', '82px']} justify="center">
          <ContentContainer maxWidth={APP_CONTENT_DESKTOP_WIDTH}>
            <JointKeyFeatures />
          </ContentContainer>
        </Flex>

        <VIP />

        <Flex bg={colors.white} className="theres-even-more-to-love">
          <ContentContainer maxWidth={APP_CONTENT_WIDE_WIDTH}>
            <CardFeatures />
          </ContentContainer>
        </Flex>

        <CouplesLoveZeta />

        <Flex justify="center" className="how-it-works">
          <ContentContainer maxWidth={APP_CONTENT_WIDE_WIDTH}>
            <HowItWorks variant="joint-accounts" />
          </ContentContainer>
        </Flex>

        <Flex bg={colors.background} className="FAQs">
          <FAQs content={JAFAQContent} />
        </Flex>

        <Flex
          background={'linear-gradient(180deg, #fff, #EAF9FB)'}
          className="helpful-resources"
        >
          <ContentContainer
            maxWidth={['100%', 'fit-content', '100%', APP_CONTENT_DESKTOP_WIDTH]}
            minWidth={'auto'}
          >
            <HelpfulResources />
          </ContentContainer>
        </Flex>

        <Anchor />
      </Flex>
    </Box>
  );
};

export default PageContent;
