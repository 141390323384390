import React from 'react';
import { Box, Flex, Image, Text, useMediaQuery } from '@chakra-ui/core';
import { colors } from '@theme';
import { navigate } from 'gatsby';
import Lottie from 'react-lottie';
import {
  KeyFeatures,
  FamiliesHeartZeta,
  Metadata,
  Anchor,
  HelpfulResources,
} from '@familyaccounts';
import ContentContainer from '../ContentContainer';
import { cloudfrontBaseUrl, FamilyFAQContent } from '../../lib/consts';
import {
  APP_CONTENT_SMALL_DESKTOP_WIDTH,
  APP_CONTENT_DESKTOP_WIDTH,
  APP_CONTENT_WIDE_WIDTH,
  APP_CONTENT_MOBILE_WIDTH,
} from '../../styles/sizes';
import VIP from '../jointAccount/VIP';
import CardFeatures from '../jointAccount/cardFeatures';
import HowItWorks from '../familyAccount/HowItWorks';
import FAQs from '../jointAccount/FAQs';
import FamilyHeroAnimation from '../../lotties/family-hero-animation-1.json';
import ZetaIsFor from '../zeta/ZetaIsFor';
const FamilyHeroLeft = `${cloudfrontBaseUrl}/homepage-24/family-accounts/family-hero-left.png`;
const FamilyHeroRight = `${cloudfrontBaseUrl}/homepage-24/family-accounts/family-hero-right.png`;
const PageContent = () => {
  const [isMobile] = useMediaQuery(`(max-width: ${APP_CONTENT_MOBILE_WIDTH})`);
  return (
    <Box customBackgroundColor="#FDF3F3">
      <Flex paddingTop={[16, 16, 24, 24]} justifyContent={'center'}>
        <Flex direction={'column'}>
          <Flex
            maxWidth={APP_CONTENT_SMALL_DESKTOP_WIDTH}
            style={{ gap: 12 }}
            width={'100%'}
            direction={['column', 'column', 'row', 'row']}
            alignItems={['center', 'center', 'inherit', 'inherit']}
          >
            <Box
              width={['50%', '50%', '30%', '30%']}
              display={'flex'}
              alignItems={'center'}
              alignSelf={['flex-start', 'flex-start', 'flex-start', 'flex-start']}
              marginLeft={['-12px', '-12px', 0, 0]}
            >
              <Image width="100%" src={FamilyHeroLeft} paddingTop={[0, 0, 12, 12]} />
            </Box>
            <Box width={['70%', '70%', '40%', '40%']} paddingLeft={[0, 0, '8px', '8px']}>
              <Lottie
                isClickToPauseDisabled
                style={{
                  cursor: 'auto',
                }}
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: isMobile ? FamilyHeroAnimation : FamilyHeroAnimation,
                }}
              />
            </Box>
            <Box
              width={['50%', '50%', '30%', '30%']}
              display={'flex'}
              alignItems={'flex-start'}
              alignSelf={['flex-end', 'flex-end', 'flex-end', 'flex-end']}
              marginRight={['-12px', '-12px', 0, 0]}
            >
              <Image width="100%" paddingBottom={[0, 0, 24, 24]} src={FamilyHeroRight} />
            </Box>
          </Flex>
          <Flex align="center" marginBottom="81px" marginTop="20px" justify="center">
            <Text
              ml={4}
              fontStyle="italic"
              width="230px"
              fontSize={'10px'}
              style={{ textAlign: 'center' }}
            >
              Zeta Accounts are deposit accounts powered by Piermont Bank, Member FDIC.
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <Flex mb={[0]}>
        <ContentContainer maxWidth={APP_CONTENT_WIDE_WIDTH}>
          <KeyFeatures />
        </ContentContainer>
      </Flex>

      <ZetaIsFor />

      <Box paddingBottom={'36px'} />

      <VIP />

      <Flex bg={colors.transparent} className="theres-even-more-to-love">
        <ContentContainer maxWidth={APP_CONTENT_WIDE_WIDTH}>
          <CardFeatures />
        </ContentContainer>
      </Flex>

      <FamiliesHeartZeta />

      <Flex justify="center" className="how-it-works">
        <ContentContainer maxWidth={APP_CONTENT_WIDE_WIDTH}>
          <HowItWorks variant="family-accounts" />
        </ContentContainer>
      </Flex>

      <Flex bg={colors.background} className="FAQs">
        <FAQs content={FamilyFAQContent} />
      </Flex>

      <Flex
        background={'linear-gradient(180deg, #fff, #FDF3F3)'}
        className="helpful-resources"
      >
        <ContentContainer
          maxWidth={['100%', 'fit-content', '100%', APP_CONTENT_DESKTOP_WIDTH]}
          minWidth={'auto'}
        >
          <HelpfulResources />
        </ContentContainer>
      </Flex>
      <Anchor />
    </Box>
  );
};

export default PageContent;
