import React from 'react';
import { Box, Flex, Image, Text, useMediaQuery } from '@chakra-ui/core';
import colors from '../../theme/colors';
import Lottie from 'react-lottie';
import Anchor from './Anchor';
import ContentContainer from '../ContentContainer';
import FAQs from '../jointAccount/FAQs';
import HelpfulResources from './HelpfulResources';
import HowItWorks from './howItWorks';
import KeyFeatures from './KeyFeatures';
import PeopleLoveZeta from './PeopleLoveZeta';
import VIP from '../jointAccount/VIP';
import {
  APP_CONTENT_DESKTOP_WIDTH,
  APP_CONTENT_SMALL_DESKTOP_WIDTH,
  APP_CONTENT_WIDE_WIDTH,
} from '../../styles/sizes';
import { cloudfrontBaseUrl, PAFAQContent } from '../../lib/consts';
import PersonalHeroAnimation from '../../lotties/personal-hero-1.json';
import CardFeatures from '../jointAccount/cardFeatures';

const PersonalHeroLeft1 = `${cloudfrontBaseUrl}/homepage-24/personal-accounts/personal-hero-left1-new.png`;
const PersonalHeroLeft2 = `${cloudfrontBaseUrl}/homepage-24/personal-accounts/personal-hero-left2.png`;
const PersonalHeroRight = `${cloudfrontBaseUrl}/homepage-24/personal-accounts/personal-hero-right.png`;

const PageContent = () => {
  const [isMobile] = useMediaQuery(`(max-width: ${APP_CONTENT_SMALL_DESKTOP_WIDTH})`);
  return (
    <Box customBackgroundColor={colors.toastedAlmond}>
      <Flex paddingTop={[16, 16, 24, 24]} justifyContent={'center'}>
        <Flex direction={'column'}>
          <Flex
            maxWidth={APP_CONTENT_SMALL_DESKTOP_WIDTH}
            style={{ gap: 12 }}
            width={'100%'}
            direction={['column-reverse', 'column-reverse', 'row', 'row']}
            alignItems={['center', 'center', 'inherit', 'inherit']}
          >
            <Flex
              width={['50%', '50%', '30%', '30%']}
              display={'flex'}
              alignItems={'center'}
              alignSelf={['flex-start', 'flex-start', 'unset', 'unset']}
              marginLeft={['-12px', '-12px', 0, 0]}
              direction={'column'}
            >
              <Image width="100%" paddingTop={[0, '50%']} src={PersonalHeroLeft1} />
              <Image width="100%" paddingTop={['24px', '48px']} src={PersonalHeroLeft2} />
            </Flex>
            <Box width={['70%', '70%', '40%', '40%']} paddingLeft={[0, 0, '8px', '8px']}>
              <Lottie
                isClickToPauseDisabled
                style={{
                  cursor: 'auto',
                }}
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: isMobile ? PersonalHeroAnimation : PersonalHeroAnimation,
                }}
              />
            </Box>
            <Box
              width={['50%', '50%', '30%', '30%']}
              display={'flex'}
              alignItems={'flex-start'}
              alignSelf={['flex-end', 'flex-end', 'unset', 'unset']}
              marginRight={['-12px', '-12px', 0, 0]}
            >
              <Image width="100%" paddingTop={[0, 0, 12, 12]} src={PersonalHeroRight} />
            </Box>
          </Flex>
          <Flex align="center" marginBottom="81px" marginTop="20px" justify="center">
            <Text
              ml={4}
              fontStyle="italic"
              width="230px"
              fontSize={'10px'}
              style={{ textAlign: 'center' }}
            >
              Zeta Accounts are deposit accounts powered by Piermont Bank, Member FDIC.
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <Flex mb={['50px', '82px']}>
        <ContentContainer maxWidth={APP_CONTENT_DESKTOP_WIDTH}>
          <KeyFeatures />
        </ContentContainer>
      </Flex>

      <VIP />

      <Flex bg={colors.white} className="theres-even-more-to-love">
        <ContentContainer maxWidth={APP_CONTENT_WIDE_WIDTH}>
          <CardFeatures />
        </ContentContainer>
      </Flex>

      <PeopleLoveZeta />

      <Flex justify="center" className="how-it-works">
        <ContentContainer maxWidth={APP_CONTENT_WIDE_WIDTH}>
          <HowItWorks variant="personal-accounts" />
        </ContentContainer>
      </Flex>

      <Flex bg={colors.background} className="FAQs">
        <FAQs content={PAFAQContent} />
      </Flex>

      <Flex
        background={'linear-gradient(180deg, #fff, #FDF9E6)'}
        className="helpful-resources"
      >
        <ContentContainer
          maxWidth={['100%', 'fit-content', '100%', APP_CONTENT_DESKTOP_WIDTH]}
          minWidth={'auto'}
        >
          <HelpfulResources />
        </ContentContainer>
      </Flex>
      <Anchor />
    </Box>
  );
};

export default PageContent;
