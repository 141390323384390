import React from 'react';

import { Metadata } from '@familyaccounts';
import AccountTabs from '../components/AccountTabs';

const JointAccount = props => (
  <>
    <Metadata statusBarColor="#E9F8FB" />
    <AccountTabs defaultTab={1} />
  </>
);

export default JointAccount;
